<div
  infinite-scroll
  [infiniteScrollDisabled]="limit !== undefined"
  (scrolled)="onScrollDown($event)"
>
  <card
    *ngFor="let resource of resultSet.posts | postsFilter: filterParams"
    (click)="onClickResult(resource)"
    [ngClass]="{
      'type-video': resource.type === 'video',
      hoverable: resource.canPreview || resource.canView
    }"
    [image]="resource.thumbnail ? resource.thumbnail.sizes.medium.url : null"
    image-placement="left"
  >
    <ng-template #defaultCardImage>
      <div
        class="default-image"
        *ngIf="!resource.thumbnail && resource.type !== 'video'"
      >
        <ab-icon
          [name]="
            resource.type === 'document'
              ? 'document'
              : resource.type === 'playlist'
                ? 'module'
                : 'document'
          "
        ></ab-icon>
      </div>
    </ng-template>

    <div class="card-title link-title">{{ resource.title }}</div>

    <ng-container *ngTemplateOutlet="cardTypeTemplate"></ng-container>

    <div
      *ngIf="resource.type === 'video'"
      class="card-subtype-label"
      i18n="common.words|Video@@commonWordVideo"
    >
      Video
    </div>

    <div
      *ngIf="resource.type === 'document'"
      class="card-subtype-label"
      i18n="common.words|Document@@commonWordDocument"
    >
      Document
    </div>

    <div
      *ngIf="resource.type === 'article'"
      class="card-subtype-label"
      i18n="common.words|Article@@commonWordArticle"
    >
      Article
    </div>

    <div
      *ngIf="resource.type === 'playlist'"
      class="card-subtype-label"
      i18n="common.words|Common Word - Module@@commonWordsModule"
    >
      Module
    </div>

    <ab-icon
      *ngIf="disableAction === false"
      class="download-link"
      (click)="onClickDownload(resource, $event)"
      name="download"
    ></ab-icon>
  </card>

  <h6
    *ngIf="(resultSet.posts | postsFilter: filterParams).length === 0"
    i18n="search|Search Results - Nothing Found@@noResultsFound"
  >
    Sorry, nothing was found for your search query.
  </h6>
</div>

<mat-progress-bar
  *ngIf="loading"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
