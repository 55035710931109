import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { AppTheme } from "@builder/common";
import { takeUntil, startWith } from "rxjs/operators";
import { WithDestroy } from "@builder/common/mixins/with-destroy";
import { ProductService } from "@builder/products/product.service";

@Component({
  selector: "search-filter",
  templateUrl: "./search-filter.component.html",
  styleUrls: ["./search-filter.component.less"],
})
export class SearchFilterComponent extends WithDestroy() implements OnInit {
  @Input() filterDefaults;

  @Output() filters: EventEmitter<any> = new EventEmitter();

  public searchFiltersForm: FormGroup;
  public themeFiltersForm: FormGroup;
  public products: Array<any>;
  public productsFilter: AbstractControl;
  public enabledProductsMap: { [index: number]: string } = {};
  public enabledProductsIds: Array<number>;
  public selectedProducts: Array<any>;
  public themes: any;
  public hasProductsSelected: boolean = false;
  public seriesSelectPlaceholder = $localize`:series.title|All Series Title@@allSeriesTitle:All Alpha Series`;

  public themesFilterList = [
    {
      title: $localize`:searchFilter.option|Search Filter Option - Best Practices@@searchFilterOptionBestPractices:Best Practices`,
      formControlName: "best-practices",
    },
    {
      title: $localize`:searchFilter.option|Search Filter Option - Prayer@@searchFilterOptionPrayer:Prayer`,
      formControlName: "prayer",
    },
    {
      title: $localize`:searchFilter.option|Search Filter Option - Leadership@@searchFilterOptionLeadership:Leadership`,
      formControlName: "leadership",
    },
  ];

  constructor(
    private fb: FormBuilder,
    public theme: AppTheme,
    private productService: ProductService,
  ) {
    super();

    this.productService.getValidProducts().subscribe((products) => {
      this.enabledProductsMap = products.reduce(
        (acc, { id, title }) => ({ ...acc, [id]: title }),
        {},
      );
      this.enabledProductsIds = products.map(({ id }) => id);
    });
  }

  ngOnInit(): void {
    this.searchFiltersForm = this.fb.group({
      type: this.fb.group({
        video: true,
        article: true,
        document: true,
      }),
      category: this.fb.group({
        material: true,
        promotional: true,
        learning: true,
      }),
      products: [],
      searchWithin: "all",
    });

    // Create Themes Form separately
    this.themeFiltersForm = this.fb.group({
      all: true,
    });

    // Add a filter (checkbox) for each theme in the list
    this.themesFilterList.forEach(({ formControlName }) => {
      this.themeFiltersForm.addControl(formControlName, new FormControl(true));
    });

    this.searchFiltersForm.addControl("themes", this.themeFiltersForm);

    try {
      this.searchFiltersForm.setValue(this.filterDefaults);
    } catch (e) {
      this.filterDefaults = {};
    }

    this.searchFiltersForm.valueChanges
      .pipe(startWith(this.filterDefaults), takeUntil(this.destroy$))
      .subscribe((v) => {
        this.productsFilter = v.products;
        this.hasProductsSelected = Boolean(v.products?.length);
        this.filters.emit(v);
      });
  }

  clearSelection() {
    this.searchFiltersForm.get("products").setValue([]);
  }
}
