import { Injectable } from "@angular/core";

import * as mixpanel from "mixpanel-browser";

import { AppTheme } from "@builder/common/util/themeSupports";
import { User } from "@builder/users/user";

import {
  MixPanelSettings,
  MixPanelStatus,
  MixPanelLogLevel,
} from "./mix-panel-settings.class";

/**
 * @see https://developer.mixpanel.com/docs/javascript
 */
@Injectable({
  providedIn: "root",
})
export class MixPanelService {
  constructor(
    protected settings: MixPanelSettings,
    protected theme: AppTheme,
  ) {
    mixpanel.init(settings.client_id, {
      debug: this.settings.log_level === MixPanelLogLevel.DEBUG,
      track_pageview: false,
      cross_subdomain_cookie: false,
      property_blacklist: ["password", "confirmPassword"],
      loaded: (mp) => this.onMixPanelReady(mp),
    });
  }

  public get people() {
    return mixpanel.people;
  }

  /** Ready callback ( don't think this is relevant given the api is packaged with the app ) */
  private onMixPanelReady(mp): void {}

  /**
   * Logout of mixpanel
   */
  public logout(): void {
    mixpanel.reset();
  }

  /**
   * Track an Event
   *
   * @param event
   * @param data
   */
  public trackEvent(event: string, data: any = null): void {
    if (!event) {
      return;
    }

    if (this.settings.status === MixPanelStatus.INACTIVE) {
      return;
    }

    if (data) {
      mixpanel.track(event, data);
    } else {
      mixpanel.track(event);
    }
  }

  /**
   *
   * @param id
   */
  public alias(id: string): void {
    if (this.settings.status === MixPanelStatus.INACTIVE) {
      return;
    }

    mixpanel.alias(id);
  }

  /**
   * Set the user
   * @param user
   */
  public setUser(user: User): void {
    const userSuperData = {
      "Currently Running Product": user.alphaStats.currentProductName,
      "Courses Created": user.alphaStats.numberAllTime,
      "Courses Created - Adult/Regular": user.alphaStats.numberRegular,
      "Courses Created - Youth": user.alphaStats.numberYouth,
      ...(user.alphaStats.recentStartDate && {
        "Last Course Start Date": user.alphaStats.recentStartDate.toISOString(),
      }),
    };

    const userData = {
      $email: user.email,
      $phone: user.intlNumber,
      $first_name: user.first_name,
      $last_name: user.last_name,
      $name: user.name,
      "Registration Date": user.registered_date.toISOString(),
      "User ID": user.id,
      "User Role": user.role_at_church,
      Organization: user.organization ? user.organization.name : null,
      ...userSuperData,
    };

    if (this.settings.status === MixPanelStatus.INACTIVE) {
      return;
    }

    // identify the user
    mixpanel.identify(user.id);

    // set their data
    mixpanel.people.set(userData);

    // register this site for them
    mixpanel.people.union("Sites", this.theme.value("site_name"));

    // register user super property stats
    mixpanel.register(userSuperData);
  }

  /**
   * Delete a user
   */
  public deleteUser(user: User): void {
    if (this.settings.status === MixPanelStatus.INACTIVE) {
      return;
    }
    mixpanel.people.delete_user();
  }

  /**
   * Increment a people value and register the update on the super properties so it gets sent with subsequent events
   */
  public incrementSuperProperty(property) {
    const value = mixpanel.get_property(property);
    const next = (value || 0) + 1;
    const ob: any = {};
    ob[property] = next;
    mixpanel.register(ob);
    mixpanel.people.increment(property);
  }

  /**
   * Set a property on a people object and as a super property
   */
  public setUserSuperProperty(property, value) {
    const ob: any = {};
    ob[property] = value;
    mixpanel.register(ob);
    mixpanel.people.set(property, value);
  }

  /**
   * Set properties that will get sent with every event
   * @param data
   */
  public setSuperProperties(data): void {
    if (this.settings.status === MixPanelStatus.INACTIVE) {
      return;
    }

    mixpanel.register(data);
  }
}

export const mixPanelSettingsProviderFactory = (appTheme: AppTheme) => {
  const obj = appTheme.value("tracking/mix-panel");
  return new MixPanelSettings(obj);
};

export let mixPanelSettingsProvider = {
  provide: MixPanelSettings,
  useFactory: mixPanelSettingsProviderFactory,
  deps: [AppTheme],
};
