import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable } from "rxjs";

import { isString } from "@builder/common/util";
import { CurrentUser } from "@builder/users/user";

import { PlaylistService } from "@builder/training/playlists/playlist-service";
import { Playlist } from "@builder/training/playlists";
import { Lesson } from "@builder/training/lessons";
import { LessonService } from "@builder/training/lessons/lesson-service";

import { TrainingSettings } from "@builder/training/training-settings";
import { Errors } from "@builder/common";
import { map, catchError } from "rxjs/operators";

/**
 * Lesson Resolver
 */

@Injectable()
export class LessonDetailResolver implements Resolve<Lesson> {
  // private lessonCountsPerPlaylist: Map<Lesson, Array<number>> = new Map<number, Array<number>>();
  private viewedLessons: Array<Lesson> = [];

  constructor(
    private lessonService: LessonService,
    private playlistService: PlaylistService,
    private currentUser: CurrentUser,
    private settings: TrainingSettings,
    protected errors: Errors,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<Lesson> | any {
    const id = route.params["id"],
      lessonIdOrSlug = route.params["lessonId"];

    if (lessonIdOrSlug) {
      // loading from a playlist url
      return this.resolvePlaylistThenLesson(id, lessonIdOrSlug);
    } else {
      // load the lesson
      return new Observable((observer) => {
        this.lessonService
          .getLesson(id, {
            _fields:
              "id,title,slug,content,excerpt,image,type,tags,categories,duration,related,video_url",
          })
          .pipe(
            catchError((error) => {
              return this.errors.navigateToError(error.error || error);
            }),
          )
          .subscribe((result) => {
            observer.next(result);
            observer.complete();
          });
      });
    }
  }

  /**
   * Get the playlist, then resolve the lesson
   */
  private resolvePlaylistThenLesson(
    id: number,
    lessonIdOrSlug: number | string,
  ) {
    // get the playlist from the request
    return this.playlistService.getPlaylist(id).pipe(
      // map the returned playlist to the requested lesson
      map((playlist: Playlist) => {
        let lesson: Lesson;
        if (isString(lessonIdOrSlug)) {
          lesson = playlist.getLessonBySlug(lessonIdOrSlug as string);
        } else {
          lesson = playlist.getLessonById(lessonIdOrSlug as number);
        }

        if (lesson) {
          // set the lesson being viewed on the playlist
          playlist.setCurrentLesson(lesson);

          // check visibility
          if (!this.canViewLesson(lesson, playlist)) {
            lesson.canView = false;
          } else {
            // track a lesson view
            this.trackLessonView(lesson, playlist);
          }

          return { playlist, lesson };
        } else {
          // lesson couldn't be found, go to 404
          throw {
            code: 404,
            message: $localize`:training.errors|Lesson Not Found@@lessonNotFound:That lesson could not be found.`,
          };
        }
      }),
      catchError((error) => {
        return this.errors.navigateToError(error.error || error);
      }),
    );
  }

  /**
   * Track a unique lesson viewed for a playlist
   */
  private trackLessonView(lesson: Lesson, playlist: Playlist = null): void {
    if (this.viewedLessons.indexOf(lesson) === -1) {
      this.viewedLessons.push(lesson);
    }
  }

  /**
   * Check if the playlist is private or has been viewed more than the free amount specified in theme.config
   */
  public canViewLesson(lesson: Lesson, playlist: Playlist = null): boolean {
    const isLoggedIn: boolean = this.currentUser.loggedIn();

    // anyone logged in can view any lesson
    if (isLoggedIn) {
      return true;
    }

    if (playlist && playlist.status === "private") {
      // part of a private playlist and we're not logged in, can't view
      return false;
    }

    // check if they're within their free lesson view limit
    const canView: boolean =
      this.viewedLessons.indexOf(lesson) >= 0 ||
      this.viewedLessons.length < this.settings.numberFreePlaylistViews;

    return canView;
  }
}
