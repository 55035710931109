import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of, tap } from "rxjs";

import { SearchService } from "../search.service";
import { TrackingEventName, TrackingService } from "@builder/tracking";

@Injectable({
  providedIn: "root",
})
export class SearchResultsResolver implements Resolve<Array<string>> {
  constructor(
    private searchService: SearchService,
    private trackingService: TrackingService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<string>> {
    const query: string = route.queryParams.q;
    if (!query) {
      return of(null);
    }

    const searchParams = { search: query };

    return this.searchService.search(searchParams).pipe(
      tap((_) => {
        this.trackingService.trigger(TrackingEventName.LibrarySearch, {
          query: searchParams,
        });
      }),
    );
  }
}
