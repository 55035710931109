<div class="search-result-list">
  <search-result *ngFor="let resource of visiblePosts" [resource]="resource">
    <ng-template *ngTemplateOutlet="cardTypeTemplate"></ng-template>
  </search-result>
  <p *ngIf="totalPosts > 0" class="mya-p color-grey-60">
    <span i18n="common.words|Common Words - Viewing@@commonWordsViewing">
      Viewing
      {{ visiblePosts.length // i18n: (ph="visible_posts") }}
      out of
      {{ totalPosts // i18n: (ph="total_posts") }}
    </span>
  </p>
  <button
    mat-button
    class="show-more"
    (click)="loadPosts()"
    [hidden]="remainingPosts.length === 0"
    i18n="common.words|Common Words - Show More@@commonWordsShowMore"
  >
    Show More
  </button>

  <p
    *ngIf="totalPosts === 0"
    class="mya-h5 color-grey-60"
    i18n="
      noResults.text|Search Results - No results
      message@@searchNoResultsMessageFilter"
  >
    Sorry, none of the search results match the current search filters.
  </p>
</div>

<mat-progress-bar
  *ngIf="loading"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
