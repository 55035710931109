import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";

import { Observable, Observer } from "rxjs";

import { CurrentUser } from "@builder/users/user";
import { Locale } from "@builder/common/lang/locale";
import { AppTheme, Errors } from "@builder/common";

/**
 * Training Center CanActivate check
 * See if the current locale doesn't specify that the center is disabled
 */
@Injectable()
export class CanAccessTrainingCenter implements CanActivate {
  constructor(
    private currentUser: CurrentUser,
    private locale: Locale,
    private appTheme: AppTheme,
    protected errors: Errors,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<any> {
    if (
      this.appTheme.supports("disable_training") ||
      !this.locale.trainingCenter.enable
    ) {
      return new Observable((observer: Observer<any>) => {
        this.errors.navigateToError(
          {
            code: 403,
            message: $localize`:training.errors|Learning Center Not Available@@trainingCentreNotAvailable:The Learning Center is not available at this time.`,
          },
          observer,
        );
      });
    }
    return true;
  }
}
