import { NgModule } from "@angular/core";

import { AlphaLocaleModule } from "../../lang/locale.module";
import { RelatedTextComponent } from "./related.component";

@NgModule({
  imports: [AlphaLocaleModule],
  declarations: [RelatedTextComponent],
  exports: [RelatedTextComponent],
})
export class RelatedTextModule {}
