export enum MixPanelLogLevel {
  NONE,
  DEBUG,
}

export enum MixPanelStatus {
  INACTIVE,
  ACTIVE,
}

export class MixPanelSettings {
  public client_id: string;
  public log_level: MixPanelLogLevel = MixPanelLogLevel.NONE;
  public status: MixPanelStatus = MixPanelStatus.ACTIVE;

  constructor(params) {
    for (const prop in params) {
      this[prop] = params[prop];
    }
  }
}
