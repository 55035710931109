import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";
import { SearchFormUpdatedComponent } from "./search-form-updated.component";

@NgModule({
  imports: [
    FormsModule,
    BuilderMaterialModule,
    BuilderIconModule,
    AlphaLocaleModule,
    CommonModule,
  ],
  declarations: [SearchFormUpdatedComponent],
  exports: [SearchFormUpdatedComponent],
})
export class SearchFormUpdatedModule {}
