import { NgModule } from "@angular/core";
import { BuilderIconComponent } from "./ab-icon.component";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [BuilderIconComponent],
  exports: [BuilderIconComponent],
  entryComponents: [BuilderIconComponent],
})
export class BuilderIconModule {}
