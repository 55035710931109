<ng-content select="label"></ng-content>

<span class="dd-label">{{
  selectedItem ? selectedItem[labelProperty] : ""
}}</span>

<ab-icon
  name="chevron-down"
  class="trigger"
  (menuOpened)="onOpen($event)"
  [matMenuTriggerFor]="optionsMenu"
></ab-icon>

<mat-menu #optionsMenu="matMenu" xPosition="before">
  <!--[overlapTrigger]="true"-->

  <div id="optionsMenu" *ngIf="optionTemplate">
    <button
      *ngFor="let option of options"
      mat-menu-item
      (click)="selectOption(option)"
    >
      <ng-template
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ option: option }"
      ></ng-template>
    </button>
  </div>
</mat-menu>
