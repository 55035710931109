import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlaylistCardModule } from "../card/playlist-card.module";
import { RelatedPlaylistsComponent } from "./related-playlists.component";

@NgModule({
  imports: [CommonModule, PlaylistCardModule],
  declarations: [RelatedPlaylistsComponent],
  exports: [RelatedPlaylistsComponent],
})
export class RelatedPlaylistsModule {}
