import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

import { UserSurvey } from "@alpha-global/alpha-surveys";
import { Playlist } from "@builder/training/playlists";

import { PlaylistService } from "../playlist-service";

/**
 * Playlist Detail Component
 */
@Component({
  selector: "playlist-detail",
  templateUrl: "playlist-detail.component.html",
  styleUrls: ["playlist-detail.component.less", "../nav-bar/lesson-list.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("openClose", [
      state(
        "open",
        style({
          transform: "translateY(0)",
          opacity: 1,
        }),
      ),
      state(
        "closed",
        style({
          transform: "translateY(200px)",
          opacity: 0,
        }),
      ),
      transition("closed => open", [animate("0.2s 0s ease-out")]),
      transition("open => closed", [animate("0.2s 0s ease-out")]),
    ]),
  ],
})
export class PlaylistDetailComponent {
  public playlist: Playlist;

  public userSurvey: UserSurvey;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: PlaylistService,
  ) {
    this.playlist = this.route.snapshot.data.playlist;

    if (this.route.parent.snapshot.data.survey) {
      this.userSurvey = this.route.parent.snapshot.data.survey;
    }
  }

  public goBack(): void {
    if (this.userSurvey?.meta?.alpha) {
      this.router.navigate([
        "/alphas",
        this.userSurvey.meta.alpha.id,
        "survey",
        "recommendations",
      ]);
    } else {
      this.router.navigate(["/training"]);
    }
  }

  public resetModule(): void {
    this.playlist.lessons.forEach((lesson) => {
      lesson.complete = false;
      delete lesson.completed_on;
    });

    this.service.resetPlaylistProgress(this.playlist).subscribe();

    if (this.userSurvey?.meta?.alpha) {
      this.router.navigate([
        "/alphas",
        this.userSurvey.meta.alpha.id,
        "survey",
        "recommendations",
        this.playlist.slug,
        this.playlist.firstIncompleteLesson.slug,
      ]);
    } else {
      this.router.navigate([
        "/training",
        this.playlist.slug,
        this.playlist.firstIncompleteLesson.slug,
      ]);
    }
  }
}
