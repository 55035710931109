import { Lesson } from "@builder/training/lessons/lesson-class";

export class Playlist {
  public title: { rendered: string };
  public content: { rendered: string };
  public excerpt: { rendered: string };
  public image: any;
  public id: number;
  public blog_id: number;
  public link: string;
  public slug: string;
  public lessons: Array<Lesson>;
  public trackProgress: boolean;
  public duration: number;
  public menu_order: number;
  public status: string;
  public type: string;
  public canView = true;
  public user_role: string;
  public tags: any;

  private _currentIndex = 0;
  public get currentIndex(): number {
    return this._currentIndex;
  }

  public get articleCount(): number {
    return this.lessons.filter((item) => item.type === "article").length;
  }

  public get videoCount(): number {
    return this.lessons.filter((item) => item.type === "video").length;
  }

  public get lessonCount(): number {
    return this.lessons.length;
  }

  public get lessonsComplete(): number {
    return this.lessons.filter((item) => item.complete).length;
  }

  public get complete(): boolean {
    return this.lessonsComplete === this.lessonCount;
  }

  constructor(obj = {}) {
    this.lessons = [];

    for (const prop in obj) {
      this[prop] = obj[prop];
    }

    let i = 0;
    const len: number = this.lessons.length;

    for (i; i < len; i++) {
      this.lessons[i] = new Lesson(this.lessons[i]);
    }
  }

  /**
   * Set the current lesson being viewed on the playlist
   */
  public setCurrentLesson(requestedLesson: Lesson = null): Lesson {
    if (!requestedLesson) {
      this._currentIndex = -1;
      return null;
    }

    let i = 0;
    const len: number = this.lessons.length;

    // check that the requested lesson exists in this collection
    // and find it's index to set as current
    for (i; i < len; i++) {
      const lesson: Lesson = this.lessons[i];

      if (lesson === requestedLesson) {
        this._currentIndex = i;

        return requestedLesson;
      }
    }

    // the lesson being set didn't exist in this playlist
    return null;
  }

  /**
   * get the percent complete by checking how many lessons have the 'complete' property = true
   */
  public get percentComplete(): number {
    let i = 0;
    let complete = 0;
    const len: number = this.lessons.length;

    for (i; i < len; i++) {
      if (this.lessons[i].complete) {
        complete++;
      }
    }

    return complete / len;
  }

  /**
   * Get the first incomplete lesson to do
   */
  public get firstIncompleteLesson(): Lesson {
    let i = 0;
    const complete = 0;
    const len: number = this.lessons.length;

    for (i; i < len; i++) {
      if (!this.lessons[i].complete) {
        return this.lessons[i];
      }
    }
    return null;
  }

  /**
   * Get the next incomplete lesson from the current lesson index
   */
  public get nextIncompleteLesson(): Lesson {
    let i: number = this._currentIndex === -1 ? 0 : this._currentIndex + 1;
    const len: number = this.lessons.length;

    for (i; i < len; i++) {
      if (!this.lessons[i].complete) {
        return this.lessons[i];
      }
    }

    const first: Lesson = this.firstIncompleteLesson;
    return first !== this.currentLesson ? first : null;
  }

  /**
   * Get the next lesson
   */
  public get nextLesson(): Lesson {
    return this.lessons[this._currentIndex + 1] || null;
  }

  /**
   * Get the previous lesson
   */
  public get previousLesson(): Lesson {
    return this.lessons[this._currentIndex - 1] || null;
  }

  /**
   * Get the current lesson
   */
  public get currentLesson(): Lesson {
    return this.lessons[this._currentIndex] || null;
  }

  /**
   * Get a Lesson by id
   */
  public getLessonById(id: number): Lesson {
    let i = 0;
    const num: number = this.lessons.length;

    for (i; i < num; i++) {
      const lesson: Lesson = this.lessons[i];
      if (lesson.id === id) {
        return lesson;
      }
    }

    return null;
  }

  /**
   * Get a Lesson by slug
   */
  public getLessonBySlug(slug: string): Lesson {
    let i = 0;
    const num: number = this.lessons.length;

    for (i; i < num; i++) {
      const lesson: Lesson = this.lessons[i];
      if (lesson.slug === slug) {
        return lesson;
      }
    }

    return null;
  }
}
