import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, map } from "rxjs";

import { AlphasService } from "@builder/alphas/service";

@Injectable({
  providedIn: "root",
})
export class LookupAlphaResolver implements Resolve<Observable<boolean>> {
  constructor(private alphasService: AlphasService) {}

  resolve(): Observable<boolean> {
    return this.alphasService.userAlphas.pipe(
      map((userAlphas) => Boolean(userAlphas.totalItems)),
    );
  }
}
