import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { GoToTextModule } from "@builder/common/text";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";

import { WhatsNewDialogComponent } from "./whats-new-modal.component";

@NgModule({
  imports: [
    CommonModule,
    BuilderMaterialModule,
    AlphaLocaleModule,
    BuilderIconModule,
    GoToTextModule,
  ],
  declarations: [WhatsNewDialogComponent],
  exports: [WhatsNewDialogComponent],
  entryComponents: [WhatsNewDialogComponent],
})
export class WhatsNewDialogModule {}
