import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FeaturesService } from "@builder/common/features/features.service";
import { FEATURE_SEARCH_UPDATE } from "@builder/common/features/feature-flag";

/**
 * Search Update Feature flag
 * Adds a classname to body tag
 */
@Injectable({
  providedIn: "root",
})
export class SearchUpdate {
  constructor(
    features: FeaturesService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    features
      .whenChanged(FEATURE_SEARCH_UPDATE)
      .subscribe(status => {
        if ( status.enabled ) {
          this.document.body.classList.add("search-update");
        } else {
          this.document.body.classList.remove("search-update");
        }
      });
  }
}
