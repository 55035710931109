import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";

import { SearchFormComponent } from "./search-form.component";

@NgModule({
  imports: [FormsModule, BuilderIconModule, AlphaLocaleModule],
  declarations: [SearchFormComponent],
  exports: [SearchFormComponent],
})
export class SearchFormModule {}
