import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { VideoPlayerModule } from "@builder/common/media/video/video-player.module";

import {
  ResourceModalComponent,
  ResourceModalDialog,
} from "@builder/resources/resource-preview/modal/resource-preview-modal.component";
import { ResourceDownloadModule } from "../../resource-download/resource-download.module";
import { ResourceAccessDeniedComponent } from "./access-denied.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BuilderMaterialModule,
    ResourceDownloadModule,
    VideoPlayerModule,
    BuilderIconModule,
  ],
  providers: [ResourceModalDialog],
  declarations: [ResourceModalComponent, ResourceAccessDeniedComponent],
  entryComponents: [ResourceModalComponent, ResourceAccessDeniedComponent],
})
export class ResourceModalModule {}
