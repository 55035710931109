import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { MatChip } from "@angular/material/chips";
import { Resource } from "@builder/resources";
import { Router } from "@angular/router";
import { ResourceModalDialog, ResourceService } from "@builder/resources";
import { CardTypeTemplateDirective } from "../../search-results.component";

@Component({
  selector: "search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.less"],
})
export class SearchResultComponent implements OnInit {
  @Input() resource: Resource;

  @Output() imageClick: EventEmitter<any> = new EventEmitter();

  @ContentChildren(MatChip) chips: QueryList<MatChip>;

  @ContentChild(CardTypeTemplateDirective, { static: true, read: TemplateRef })
  cardTypeTemplate: TemplateRef<any>;

  @ContentChild("defaultCardImage", { static: true })
  defaultCardImage: TemplateRef<any>;

  public image = null;
  public videoDuration: number | null = null;

  public featureList = [];
  public featuredInCount: number = 0;
  public attachedFileType: string;
  public isBlockedContent: boolean = false;
  public category: string = "";

  protected i18n = {
    category: {
      material: $localize`:common.words|Series Material@@commonWordSeriesMaterial:Series Material`,
      promotional: $localize`:common.words|Promotional Material@@commonWordPromotionalMaterial:Promotional Material`,
      learning: $localize`:common.words|Learning Module@@commonWordLearningModule:Learning Module`,
    },
  };

  constructor(
    private mediaModal: ResourceModalDialog,
    private router: Router,
    private resourceService: ResourceService,
  ) {}

  public ngOnInit(): void {
    this.image = this.resource.image
      ? this.resource.image.sizes.thumbnail.url
      : this.resource.thumbnail?.sizes.thumbnail.url;
    this.videoDuration =
      this.resource.type == "video" && this.resource.video?.duration
        ? this.resource.video?.duration
        : this.resource.duration
          ? this.resource.duration
          : null;
    this.featureList = this.resource?.products?.map((x) => x.title);
    this.featuredInCount = this.featureList?.length;
    this.attachedFileType = this.resource?.attachedDocument?.file
      ? this.resource?.attachedDocument?.file.split(".").pop()
      : this.resource.type;

    this.isBlockedContent =
      this.resource.error && this.resource.error.productAccess;

    this.category = this.resource.category
      ? this.i18n.category[this.resource.category]
      : "";
  }
  /**
   * Clicked the image portion, show a preview if available
   */
  public onClickResult(resource: Resource): void {
    const link = this.getRouterLink(resource);
    if (link) {
      this.router.navigate(link);
    } else if (resource.canPreview || resource.error) {
      this.mediaModal.open(resource);
    }
  }

  /**
   * Clicked to download
   */
  public onClickDownload(resource: Resource, event: MouseEvent): void {
    event.stopPropagation();

    if (resource.type === "video") {
      this.mediaModal.open(resource, { skipPreview: true });
    } else if (resource.type === "document" && resource.attachedDocument) {
      this.resourceService.onDownload(resource);
    }
    if (resource.error) {
      return;
    }
  }

  private getRouterLink(resource): Array<string> {
    if (resource.is_playlist) {
      return ["/training", resource.slug];
    }
    if (resource.is_lesson) {
      return ["/training", "article", resource.slug];
    }
    return null;
  }

  public onNavigateToSeriesDetails(resource: Resource): void {
    const slug = resource?.error?.productAccess.slug;

    this.router.navigate([slug]);
  }
}
