import { Post } from "@builder/common/models/post";
import { Attachment } from "@builder/common/models/attachment";

const getIconName = (resource: Resource): string => {
  if (resource.type === "video") {
    return "movie";
  }
  if (resource.attachedDocument) {
    const ext: string = resource.attachedDocument.file.substring(
      resource.attachedDocument.file.lastIndexOf(".") + 1,
    );

    switch (ext) {
      case "docx":
      case "doc":
      case "txt":
      case "rtf":
        return "document-filled";

      case "zip":
        return "folder";

      default:
        break;
    }
  }

  return "document";
};

export const isResourcePreviewable = (resource: Resource): boolean => {
  if (resource.type === "video") {
    return true;
  }
  if (resource.attachedDocument) {
    const ext: string = resource.attachedDocument.file.substring(
      resource.attachedDocument.file.lastIndexOf(".") + 1,
    );

    if (["pdf", "png", "jpg"].includes(ext)) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

interface ProductAccess {
  slug: string;
  title: string;
}

export class Resource extends Post {
  public attachedDocument: Attachment;
  public discussionGuide: Attachment;
  public relatedDocuments: Array<Resource>;
  public video;
  public video_url;
  public thumbnail;
  public image;
  public type: string;
  public language: string;
  public iconName: string;
  public url: string;
  public canPreview: boolean;
  public error: any;
  public products: Array<{ id: number; blog_id: number; title: string }>;
  public categories: Array<string>;
  public duration: number;
  public productAccess: ProductAccess;
  public is_lesson: boolean;
  public category: string;

  constructor(data: any = null) {
    super(data);

    if (this.relatedDocuments) {
      this.relatedDocuments = this.relatedDocuments.map(
        (item) => new Resource(item),
      );
    }

    this.iconName = getIconName(this);

    this.canPreview = isResourcePreviewable(this);
  }
}
