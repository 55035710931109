import {
  Component,
  Output,
  EventEmitter,
  Input,
  PipeTransform,
  Pipe,
} from "@angular/core";
import { FeaturesService } from "@builder/common/features/features.service";
import { ActivatedRoute } from "@angular/router";
import { Observable, iif, map, mergeMap, of } from "rxjs";
import { SearchService } from "../search.service";
import { FEATURE_SEARCH_UPDATE } from "@builder/common/features/feature-flag";

export interface ISearchTerm {
  ID: number;
  count: number;
  created: Date;
  updated: Date;
  language: string;
  term: string;
}

@Pipe({
  name: "excludeTerms",
})
export class ExcludeTermsPipe implements PipeTransform {
  transform(terms: Array<ISearchTerm>, excludeTerms: Array<string>) {
    return terms.filter((f) => {
      return excludeTerms.indexOf(f.term) === -1;
    });
  }
}
@Component({
  selector: "popular-search-terms",
  templateUrl: "./popular-search-terms.component.html",
  styleUrls: ["popular-search-terms.component.less"],
})
export class PopularSearchTermsComponent {
  @Output() selectTerm: EventEmitter<any> = new EventEmitter();

  @Input() exclude: Array<string> = [];

  public terms$: Observable<any>;
  public terms: Array<ISearchTerm> = [];
  public searchUpdateIsEnabled: any;

  constructor(
    private feature: FeaturesService,
    private route: ActivatedRoute,
    private searchService: SearchService,
  ) {
    this.terms$ = this.route.data.pipe(
      map((data) => data.popularTerms),
      mergeMap((terms) =>
        iif(() => !!terms, of(terms), this.searchService.getTerms()),
      ),
    );

    this.searchUpdateIsEnabled = this.feature.isOn(FEATURE_SEARCH_UPDATE);
  }
}
