import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { BuilderIconModule } from "@builder/common/icons/ab-icon.module";
import { CommonPipesModule } from "@builder/common/pipes/common-pipes.module";
import { AlphaLocaleModule } from "@builder/common/lang/locale.module";

import { PlaylistNavBarComponent } from "./nav-bar.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AlphaLocaleModule,
    BuilderIconModule,
    CommonPipesModule,
  ],
  declarations: [PlaylistNavBarComponent],
  exports: [PlaylistNavBarComponent],
})
export class PlaylistNavBarModule {}
