<card class="hoverable">
  <ng-template #defaultCardImage>
    <div class="default-image card-icon">
      <ab-icon
        [name]="lesson.type === 'article' ? 'article' : 'play'"
      ></ab-icon>
    </div>
  </ng-template>

  <div class="link-title">{{ lesson.title }}</div>
  <div class="lesson-below-title">
    <span *ngIf="playlist">{{ playlist.title }}</span>

    <span
      i18n="common.words|Article@@commonWordArticle"
      *ngIf="lesson.type === 'article' && !playlist"
      >Article</span
    >

    <span
      i18n="common.words|Video@@commonWordVideo"
      *ngIf="lesson.type === 'video' && !playlist"
      >Video</span
    >

    <span class="lesson-duration">{{ lesson.duration | playTime }}</span>
  </div>
</card>
